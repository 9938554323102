.tab-wrapper {
  width: 100%; 
  height: 100%;
  align-items: center;
  display: flex;
  min-width: '900px';
  overflow-y: auto;
}

.tab-button {
  color: #555;
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 17px;
}

.selected {
  color: #685AFF !important;
}

.tab-upload-button {
  color: #ffffff;
  display: flex;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 17px;
  padding: 16px;
  background: #6f42c1;
  border-color: #6f42c1;
  float: right;
  margin-right: 20px;
}

.tab-upload-button:hover {
  box-shadow: 0px 4px 8px 0px rgba(20, 20, 20, 0.5);
  transform: scale(1.05);
  background: #6f42c1 !important;
  border-color: #6f42c1 !important;
}