.loading-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.spinner {
  color: #9CA3AF; /* Tailwind's gray-400 */
  position: relative;
  overflow: hidden;
  text-align: center;
  animation: spin 0.4s linear infinite;
}

/* Small spinner size */
.spinner.small {
  width: 20px;
  height: 20px;
}

/* Default spinner size */
.spinner {
  width: 24px;
  height: 24px;
}

@keyframes spin {
  from {
      transform: rotate(0deg);
  }
  to {
      transform: rotate(360deg);
  }
}
