.user-manage-section {
  padding: 50px 200px;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  font-family: 'Roboto', sans-serif !important;
}

.user-manage-title {
  font-size: 30px !important;
  font-weight: bold !important;
}

.user-manage-subtitle {
  font-size: 22px !important;
  font-weight: bold !important;
  text-align: center;
}

.user-manage-subdescription {
  color:  grey !important;
  text-align: center;
  font-weight: bold !important;
}

.user-manage-next-button {
  margin-top: 15px !important;
}

.user-manage-section .ant-picker {
  padding: 0px !important;
}

.user-manage-confirm-button {
}

.user-manage-return-button {
  float: right;
  cursor: pointer;
  margin-top: 15px;
  transition: transform 0.5s ease; 
}

.user-manage-return-button:hover {
  transform: scale(1.2);  
}