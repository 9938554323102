.account-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px 50px 10px 50px;
  animation: fade-in 1s;
}

@keyframes fade-in {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.account-header {
  font-size: 20px;
  font-family: "Roboto", sans-serif;
}

.account-header > h2 {
  margin-left: 20px;
}

.account-main {
  margin-top: -15%;
}

.account-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}

.account-avatar {
  padding-right: 40px;
}

.account-info {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.account-label {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 17px;
  font-weight: 600;
}

strong {
  color: green;
}

.account-name {
  padding-top: 12px;
}

.account-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.account-button {
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #685aff;
  color: #685aff;
  font-size: 16px;
  cursor: pointer;
  font-weight: 500;
}

.account-button:hover,
.account-button:active,
.account-button:focus {
  background: #685aff;
  color: white;
}

.account-button.selected {
  background: #685aff;
  color: white !important;
}

.account-edit-information {
  margin: 10px;
}

.account-change-password {
  margin: 10px;
}

.account-studio {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.edit-studio {
  width: 40%;
  height: 100%;
  padding: 20px 20px 20px 50px;
  border-left: 1px solid #685aff;
  animation: fade-in 1s;
}

/* .account-main {
  width: 50%;
} */

.edit-times {
  position: absolute;
  font-size: 20px;
  color: #685aff;
  font-weight: bold;
  cursor: pointer;
}