/* Login Container */
.login-container {
  background-color: #f0f2f5 !important; /* Soft background color */
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* Login Card */
.login-card {
  width: 100%;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  overflow: hidden;
}

.login-card .ant-card-head-title {
  font-size: 20px;
}

/* Override Ant Design Input Styles */
.login-card .ant-input {
  border-radius: 10px !important; /* Rounded input fields */
  padding: 10px !important;
}

/* Override Ant Design Input Styles */

.login-card .ant-input-password {
  border-radius: 10px !important; /* Rounded input fields */
  padding-left: 10px !important;
}

/* Icons inside inputs */
.login-card .ant-input-prefix {
  color: rgba(0, 0, 0, 0.25) !important;
}

/* Login Button */
.login-card .login-form-button {
  width: 100%;
  margin-top:30px;
  border-radius: 22px;
  font-size: 16px;
  padding: 5px 0;
  height: 40px;
  background-color: #1890ff;
  border-color: #1890ff;
  box-shadow: 0 4px 8px rgba(24,144,255,0.3);
}

.login-card .login-form-button:hover {
  background-color: #40a9ff;
  border-color: #40a9ff;
}
