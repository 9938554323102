.user-section {
  width: 100%;
  height: 100%;
  padding: 10px 50px;
}

.user-title {
  width: 100%;
  margin-left: 20px;
  font-family: 'Roboto', sans-serif;
  font-size: 26px;
  margin-bottom: 15px;
  max-height: 32px;
}

.user-breakline {
  width: 100%;
  height: 1px;
  background-color: lightgrey;
  box-shadow: 0px 1px 0px 0px lightgrey;
}

.user-function-list {
  max-height: 35px;
  width: 100%;
  margin: 20px 0px;
}

.user-all-select {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  margin-left: 15px;
  font-weight: bold;
}

.user-button {
  float:right;
  margin-left: 15px;
}

.user-csv-button {
  background-color: #0b9711;
  color: white;
  border: 1px solid #0b9711 !important;
  display: flex;
  align-items: center;
}

.user-csv-button:hover {
  background-color: #3ebd44 !important;
  color: white !important;
  border: 1px solid #3ebd44 !important;
}

.user-delete-button {
  border-color: red;
  color: red;
  transition: 0.3s;
}

.user-delete-button:hover {
  border-color: red !important;
  color: white !important;
  background-color: rgb(252, 77, 77) !important;
}

.user-table-wrapper {
  max-height: calc(100% - 125px);
  overflow-y: auto;
}

/* Custom CSS class for the Checkbox component */
.user-section .ant-checkbox-inner {
  border-color: #000000 !important; /* Example with a red border */
}

/* When the Checkbox is checked */
.user-section .ant-checkbox-checked .ant-checkbox-inner {
  border-color: #fc7c7e !important; /* Example with a red border */
  background-color: #fc7c7e !important; /* Optional: change background color as well */
}