.user-email-section {
  padding: 50px 200px;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  font-family: 'Roboto', sans-serif !important;
}

.user-email-title {
  font-size: 30px !important;
  font-weight: bold !important;
}

.user-email-return-button {
  float: right;
  cursor: pointer;
  margin-top: 15px;
  transition: transform 0.5s ease; 
}

.user-email-return-button:hover {
  transform: scale(1.2);  
}