.adduser-section {
  padding: 50px 200px;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  font-family: 'Roboto', sans-serif !important;
}

.adduser-title span{
  font-family: 'Roboto', sans-serif !important;
  font-size: 28px !important;
  font-weight: bold !important;
  margin-left: 20px;
}

.adduser-form input {
  /* padding: 10px !important; */
  font-size: 16px;
  border-color: darkgrey;
  border-width: 1px;
  background-color: white;
}

.adduser-submit-button {
  background-color: #2f9ed1 !important;
  color: white !important;
  border: 1px solid #2f9ed1 !important;
  float: right;
  padding: 5px 20px !important;
}
.adduser-submit-button:hover {
  background-color: #127eb1 !important;
  color: white !important;
  border: 1px solid #127eb1 !important;
}

.adduser-cancel-button {
  background-color: #d1702f !important;
  color: white !important;
  float: right;
  margin-left: 20px !important;
  padding: 5px 20px !important;
}
.adduser-cancel-button:hover {
  background-color: #eb6308 !important;
  color: white !important;
}
