.header {
  text-align: center !important;
  color: #fff !important;
  height: 64 !important;
  padding-inline: 48 !important;
  line-height: 64px !important;
  background-color: #4096ff !important;
};
.content {
  text-align: center !important;
  min-height: 120 !important;
  line-height: 100px !important;
  color: #fff !important;
  background-color: #0958d9 !important;
};
.sider {
  text-align: center !important;
  line-height: 120px !important;
  color: #fff !important;
  background-color: #1677ff !important;
};
.layout  {
  border-radius: 8 !important;
  overflow: hidden !important;
  width: 100% !important;
  max-width: 100% !important;
  min-height: 100vh !important;
};