.user-table tr {
  font-family: 'Roboto', sans-serif !important; 
}

.user-table td {
  padding: 8px 16px !important;
}

.usertable-manage-button {
  background-color: rgba(11, 151, 17, 0.2);
  color: black;
}

.usertable-manage-button:hover {
  background-color: rgba(11, 151, 17, 0.4) !important;
  color: black !important;
  border-color: rgba(11, 151, 17, 0.4) !important;
}

.usertable-email-button {
  margin-top: 5px;
  border-color: #685AFF;
  color: #685AFF;
}

.usertable-email-button:hover {
  background-color: rgba(104, 90, 255, 0.2) !important;
  border-color: rgba(104, 90, 255, 0.2) !important;
}

.usertable-reset-password-button {
  margin-top: 5px;
  border-color: #bb3928;
  color: #bb3928;
}

.usertable-reset-password-button:hover {
  background-color: rgba(214, 129, 72, 0.2) !important;
  border-color: rgba(214, 129, 72, 0.2) !important;
}
