.edit-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fade-in 1s;
  margin-top: -15%;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.edit-header {
  color: #685aff;
}

.edit-label {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.edit-label > label {
  margin-bottom: 10px;
  font-weight: 500;
}

.edit-input {
  padding: 10px 20px;
  font-size: 14px;
  width: 100%;
  border-radius: 10px;
  color: black;
  border: 2px solid #b7b7b7;
}

.edit-input::placeholder {
  color: #b7b7b7;
}

.edit-input:focus-visible {
  outline: none;
}

.edit-content {
  width: 100%;
}

.edit-footer {
  margin-top: 20px;
  width: 100%;
}

.edit-button {
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #685aff;
  color: #685aff;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  width: 100%;
  text-align: center;
}

.edit-button:hover {
  background: #685aff;
  color: white;
}

.edit-button.selected {
  background: #685aff;
  color: white !important;
}

.edit-button.disabled {
  background: #9391b1;
  color: white;
  border: none;
  pointer-events: none;
}

.button-group {
  display: flex;
  flex-direction: row;
}

.button-group > .one-plan {
  margin-right: 10px;
}
