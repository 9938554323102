.change-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fade-in 1s;
  margin-top: -15%;
}

@keyframes fade-in {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

.change-header {
  color: #685aff;
}

.change-label {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.change-label > label {
  margin-bottom: 10px;
  font-weight: 500;
}

.change-input {
  padding: 10px 20px;
  font-size: 14px;
  width: 100%;
  border-radius: 10px;
  color: black;
  border: 2px solid #b7b7b7;
}

.change-input.confirm-password {
  margin-top: 10px;
}

.change-input::placeholder {
  color: #b7b7b7;
}

.change-input:focus-visible {
  outline: none;
}

.change-content {
  width: 100%;
}

.change-footer {
  margin-top: 20px;
  width: 100%;
}

.change-button {
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #685aff;
  color: #685aff;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  width: 100%;
  text-align: center;
}

.change-button:hover {
  background: #685aff;
  color: white;
}

.change-button.selected {
  background: #685aff;
  color: white !important;
}

.change-button.disabled {
  background: #9391b1;
  color: white;
  border: none;
  pointer-events: none;
}

.button-group {
  display: flex;
  flex-direction: row;
}

.button-group > .one-plan {
  margin-right: 10px;
}
